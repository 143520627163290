@import "../vars.scss";

.sk-toggle {
  display: flex;
  align-content:center;
  text-align: center;
  border-radius: $sk-border-radius;
  max-height:30px;

  &.is-disabled {
    display:none;
  }

  &-option {
    flex:1;
    background-color: $sk-selector-background;
    border:1px solid $sk-selector-border-color;
    cursor:pointer;
    font-weight: $sk-medium-weight;
    font-size:$sk-list-font-size;
    color: $sk-selector-text-color;
    padding:5px 10px;
    border-right:none;
    text-decoration: none;
    white-space: nowrap;

    &:first-child {
      border-radius: $sk-border-radius 0 0 $sk-border-radius;
    }

    &:last-child {
      border-radius: 0 $sk-border-radius $sk-border-radius 0;
      border-right:1px solid $sk-selector-border-color;
    }

    &.is-active {
      background-color:$sk-selector-active-background;
      color:$sk-selector-active-text-color;
      border-color:$sk-selector-active-background;
    }

    &.is-disabled {
      cursor: initial;
      color: $sk-disabled-text-color;
    }

    &__text, &__count {
      display: inline;
    }

    &__count {
      &:before {
        content: " ("
      }

      &:after {
        content: ")"
      }
    }
  }
}

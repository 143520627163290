@import "filter-item.scss";
@import "../vars.scss";

.sk-item-list {

  &.is-disabled  {
    display:none;
  }

  &__options {

  }

  &__items {

  }

  &-option {
    @include filter-option;

    &.is-histogram {
      z-index: 1;
    }

    &.is-histogram &__count {
      padding-right: 4px;
      color: #999;
    }

    &__bar {
      position:absolute;
    }

    &__bar {
      &-container {
        position: absolute;
        right: 0;
        top:3px;
        bottom:3px;
        width:40%;
        max-width:60px;
        display: inline-block;
        z-index: -1;
        background: lighten(#f4f4f4,0);
        border-radius:8px;
        overflow: hidden;
      }

      display: inline-block;
      position:absolute;
      background: darken(#f4f4f4,5);
      right:0;
      top:0;
      bottom:0;
      border-radius:8px;
    }
  }
}

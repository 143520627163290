@import "filter-item.scss";
@import "../vars.scss";

.sk-hierarchical-menu {

  &-list {

    &.is-disabled {
      display:none;
    }

    &__header {
      margin-bottom:5px;
      font-weight:$sk-bold-weight;
      font-size:$sk-heading-font-size;
      color: $sk-title-color;
    }

    &__root {
      margin-left:-10px;
    }

    &__hierarchical-options {
      margin-left:10px;
    }
  }

  &-option {
    @include filter-option;
  }

}

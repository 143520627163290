@import "../vars.scss";

.sk-layout__filters {
  .sk-panel {
    margin-bottom: 16px;
  }
}

.sk-action-bar {
  .sk-panel {
    &__header {
      display: none;
    }
  }
}

.sk-panel {
  width: 100%;

  &__header {
    color: $sk-title-color;
    margin-bottom: 5px;
    font-weight: $sk-bold-weight;
    font-size: $sk-heading-font-size;

    &.is-collapsable  {
      cursor: pointer;
      user-select: none;
      padding-left:15px;
      position: relative;

      &:after {
        content:"";
        display:block;
        position:absolute;
        top:50%;
        margin-top:-3px;
        left: -1px;
        @include sk-arrow-down(5px);
      }

      &.is-collapsed:after {
        @include sk-arrow-right(5px);
        margin-top:-5px;
        left: 0;
      }

    }

  }

  &__content {
    &.is-collapsed  {
      display:none;
    }
  }

  &.is-disabled  {
    display:none;
  }
}

@import "../vars.scss";

.sk-range-input {
  width: 100%;
  align-content: center;

  &__input {
    display: inline-block;
    width: 68px;
    border-radius: $sk-border-radius;
    border: 1px solid $sk-border-color;
    height:30px;
    padding:0 10px;

    &.is-error {
      border-color: $sk-remove-action-color;
    }
  }

  &__submit {
    @extend .sk-action-button;
    display: inline-block;
    background:white;
    height:30px;
    padding:0 10px;
    margin-left:8px;
  }

  &__to-label {
    display: inline-block;
    line-height:30px;
    margin: 0 8px;
    color: $sk-text-color;
    font-size: $sk-text-font-size;
  }
}

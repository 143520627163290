@import "../vars.scss";

.filter-item {

}

@mixin filter-option {
  position:relative;
  cursor:pointer;
  padding:0px 0;
  line-height:22px;
  display:flex;
  font-weight: $sk-light-weight;

  input#{&}__checkbox {
    margin: 5px 5px 0 1px;
    flex: 0 0 15px;
  }

  &.is-active &__text {
    font-weight:$sk-bold-weight;
    color: $sk-action-active-color;
  }

  &.is-selected &__text {
    font-weight:$sk-bold-weight;
    color: $sk-action-active-color;
  }

  &__text {
    flex:auto;
    font-size:$sk-list-font-size;
    color:$sk-list-text-color;
  }

  &__count {
    font-size: $sk-list-secondary-font-size;
    text-align: right;
    color: $sk-list-secondary-text-color;
  }
}

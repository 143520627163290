@import "../vars.scss";

.sk-range-histogram {
  height:50px;
  flex-flow: row nowrap;
  justify-content: space-around;
  display:flex;
  align-items: flex-end;

  &__bar {
    background:rgba(156,162,153,0.5);
    flex:1;

    &.is-out-of-bounds {
      background:rgba(156,162,153,0.3);
    }
  }
}

.rc-tooltip {
  font-family: $sk-font-family;
}

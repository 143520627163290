.sk-spinning-loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-left: 2px solid #000;
  transform: translateZ(0);
  animation: spinning-loader 1.1s infinite linear;
}
.sk-spinning-loader,
.sk-spinning-loader:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

@keyframes spinning-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

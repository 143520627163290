@import "../vars.scss";

.sk-range-slider {
  padding-bottom: 16px; // Take into account marks

  .rc-slider-handle {
    border-color: $sk-border-color;
  }
  .rc-slider-track {
    background-color: rgba(156, 162, 153, 0.5);
  }

  .rc-slider-mark-text-active {
    color: $sk-list-secondary-text-color;
  }

  .rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: $sk-list-secondary-text-color;
  }
}

@import "../vars.scss";
@import "actions.scss";

.sk-no-hits {
  text-align:center;
  margin-top:60px;
  color: $sk-text-color;

  &__info {
    font-size:$sk-heading-font-size;
  }

  &__steps {
    margin-top:20px;
  }

  &__step-action {
    @extend .sk-action-button;
  }
}

@import "filter-item.scss";
@import "../vars.scss";

.sk-refinement-list {

  &__header {
    margin-bottom:5px;
    font-weight:$sk-bold-weight;
    font-size: $sk-heading-font-size;
  }

  &.is-disabled  {
    display:none;
  }

  &__view-more-action {
    font-size:$sk-list-secondary-font-size;
    color:$sk-action-text-color;
    cursor:pointer;
    margin-top:5px;
  }

  &-option {
    @include filter-option;
  }

}

@import "../vars.scss";

.sk-selected-filters {
  display:flex;
  flex-flow: row wrap;
  margin-right:10px;
  
  &__item {
    flex:0 0 auto;
    margin:0 10px 10px 0;
  }
  
  &-option {
    background:$sk-selected-filters-background;
    border:none;
    display:flex;
    padding:10px 0 10px 10px;
    font-size:$sk-text-font-size;
    line-height:20px;
    border-radius:3px;


    &__name {
      flex:auto;
      color:$sk-action-text-color;
    }

    &__remove-action {
      flex:auto;
      padding:0 10px;
      cursor:pointer;
      font-size:16px;
      line-height:18px;
      color:$sk-action-text-color;
      font-weight:700;
      border-left:1px solid #ddd;
      padding: 0 15px 0 10px;
      margin-left:10px;

      &:hover {
        color: $sk-remove-action-color;
      }
    }
  }
}

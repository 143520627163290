@import "../vars.scss";

.sk-reset-filters {
  text-align:center;
  border-radius:$sk-border-radius;
  padding:10px 20px;
  cursor:pointer;
  color: $sk-active-bg-color;

  &.is-disabled {
    cursor:default;
    color:$sk-disabled-text-color;
  }

  &__text {
    font-size:$sk-list-secondary-font-size;
  }

}

@import "~rc-slider/assets/index.css";
@import "../vars.scss";

.sk-range-filter {

  &__header {
    margin-bottom: 5px;
    font-weight: $sk-bold-weight;
    font-size: $sk-heading-font-size;
  }

  &.is-disabled {
    display:none;
  }

  &.is-no-histogram .bar-chart {
    margin-top:15px;
  }

  &-value-labels {
    display:flex;
    justify-content: space-between;
    margin-top:5px;

    &__min {
      align-self: flex-start;
      font-size:$sk-list-secondary-font-size;
    }
    &__max {
      align-self: flex-end;
      font-size:$sk-list-secondary-font-size;
    }
  }

}

.bar-chart {
  height:50px;
  flex-flow: row nowrap;
  justify-content: space-around;
  display:flex;
  align-items: flex-end;

  &__bar {
    background:rgba(156,162,153,0.5);
    flex:1;

    &.is-out-of-bounds {
      background:rgba(156,162,153,0.3);
    }
  }



}

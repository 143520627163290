@import "../vars.scss";

.sk-action-button {
  border:1px solid $sk-border-color;
  color:$sk-action-text-color;
  border-radius:$sk-border-radius;
  display:inline-block;
  padding:10px 20px;
  cursor:pointer;
  font-size: $sk-action-font-size;
  outline:none;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: black;
  }
}

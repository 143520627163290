@import "../vars.scss";

.sk-filter-group {

  &-items {
    display: inline-block;

    &__title, &__list, &__value {
      display: inline-block;
    }

    &__title {
      font-weight: bold;

      &:after {
        content: ": ";
      }
    }

    &__value {
      padding-left: 4px;

      &:hover {
        text-decoration: line-through;
        cursor: pointer;
        color: $sk-remove-action-color;
      }

      &:after {
        display:inline-block;
        content: ", ";
      }

      &:last-child:after {
        content: "";
      }
    }

  }

  &__remove-action {
    display: inline-block;
    cursor: pointer;
    font-weight: $sk-bold-weight;
  }
}

.sk-action-bar {
  .sk-filter-groups {
    display: flex;
    flex-flow: row;
    margin: 0 10px 10px 0;
    color: $sk-action-text-color;
  }

  .sk-filter-group {
    border: none;
    background: $sk-selected-filters-background;
    display: inline-block;
    padding: 0 0 0 10px;
    font-size: $sk-list-font-size;
    line-height: 20px;
    border-radius: $sk-border-radius;
    margin-right: 10px;

    &-items {

      &__title {
        // color: #666;
        font-weight: $sk-light-weight;
      }

      &__list {
        padding:10px 0;
      }

    }

    &__remove-action {
      border-left:1px solid #ddd;
      padding: 0 15px 0 10px;
      margin-left:10px;
      font-size: 14px;

      &:hover {
        color: $sk-remove-action-color;
      }
    }
  }
}

.sk-layout__filters {
  .sk-filter-groups {
    display: block;
    width: 100%;
    margin-right: 10px;
    color: $sk-list-text-color;
  }

  .sk-filter-group {
    border: none;
    display: block;
    font-size: $sk-list-font-size;
    line-height: 20px;

    &__remove-action {
      padding-right: 10px;
    }
  }
}

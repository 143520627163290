@import "vars.scss";
@import "components.scss";

body {
  margin:0;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.sk-layout {
  box-sizing: border-box;
  background: $sk-layout-background;
  font-family: $sk-font-family;

  &__top-bar {
    background: $sk-primary-theme-color;
    height:60px;
    position: fixed;
    top: 0;
    left:0;
    right:0;
    width: 100%;
    padding:10px 0;
    z-index:100;
    display:flex;
    box-shadow: 0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28);
  }

  &__body {
    display:flex;
    margin:60px auto 0;
    min-width:600px;
  }

  &__filters {
    background:$sk-layout-panel-background;
    padding:15px;
    margin:15px 0 15px 15px;
    flex:0 0 246px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.08);

    .sk-refinement-list,
    .sk-range-filter,
    .sk-hierarchical-menu-list,
    .sk-numeric-refinement-list,
    .sk-hierarchical-refinement-list {
      margin-bottom:20px;
    }

    .sk-select {
      width:100%;
    }

    .sk-range-input {
      margin-top:10px;
    }

    // .sk-selected-filters {
    //   flex-flow: column nowrap;
    //   &-option {
    //     margin-right:0;
    //     margin-bottom:10px;
    //   }
    // }
  }

  &__results {
    background:$sk-layout-panel-background;
    margin:15px 15px 15px 15px;
    flex:auto;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.08);
    min-height:600px;
  }

}

.sk-top-bar {
  &__content {
    display:flex;
    margin:0 auto;
    padding:0 15px;
    width:100%;
  }
}

.sk-layout {

  &__size-l {
    .sk-top-bar__content {
      width:1280px;
    }

    .sk-layout__body {
      max-width:1280px;
    }
  }

  &__size-m {

    .sk-top-bar__content {
      width:960px;
    }

    .sk-layout__body {
      max-width:960px;
    }

  }
}

.sk-action-bar {
  display:flex;
  flex-flow: column wrap;
  margin:0 15px 15px;

  &-row {
    display:flex;
    flex-flow: row wrap;
    margin:0px 0 10px;

    &:last-child {
      margin:0;
    }
  }

  .sk-hits-stats {
    line-height: 30px;
  }
}

.sk-results-list {
  padding:15px 0;
}

// components

.sk-search-box {
  background: rgba(255,255,255,.15);
  border-radius: $sk-border-radius;
  position: relative;
  display: flex;
  flex:1;
    
  &.is-focused {
    background:white;
  }

  &.is-focused &__text {
    color:$sk-text-color;
  }

  &.is-focused &__text::-webkit-input-placeholder  {
    color:$sk-text-color;
    font-weight:$sk-light-weight;
  }
  
  form {
    width:100%;
  }
  
  input.sk-search-box__text {
    -webkit-box-shadow: none;
    box-shadow: none;
    background:transparent;
    color:$sk-search-color;
    font-size:16px;
    padding-left:10px;
    
    &::-webkit-input-placeholder  {
      color:$sk-search-placeholder-color;
      font-weight:100;
    }
  }
  
  &__icon {
    margin:10px 0 0 15px;
  }
    
  &__action {
    display:none;
  }
}

.sk-hits-stats {
  align-self: flex-start;
  flex:1;
}

.sk-range-filter {
  .rc-slider-handle {
    border-color: $sk-primary-theme-color;
  }
  .rc-slider-track {
    background-color: rgba(156, 162, 153, 0.5);
  }
}

.sk-reset-filters {
  border:none;
  display:flex;
  font-size:14px;
  margin:0;
  padding-left:0;
}

.sk-pagination-navigation {
  width:300px;
  margin:10px auto;

  &.is-numbered {
    width:600px;
  }

}

.sk-pagination-select {
  margin: 10px auto;
  text-align:center;
}

.sk-hits-grid, .sk-hits-list {
  margin:0 15px 20px;
}

.sk-hits-grid {
  display:flex;
  flex-flow: row wrap;
  align-items: flex-start;

  &__no-results {
    text-align:center;
    margin-top:60px;
  }

  &__item {
    flex:1 1 250px;
    display:flex;
    margin-bottom:20px;
  }
}

.sk-hits-grid-hit {
  padding:10px;
  max-width:250px;
  flex-flow: column nowrap;
  align-content: center;
  align-items: center;
  flex: 190px 0 0;

  em {
    background:yellow;
  }

  a {
    text-decoration: none;
    color: $sk-action-text-color;
  }
  &__title {
    margin-top:10px;
    font-size:14px;
  }
}

.sk-hits-list {
  display:flex;
  flex-flow: column wrap;
  align-items: flex-start;

  em {
    background:yellow;
  }

  a {
    text-decoration: none;
    color: $sk-action-text-color;
  }

  &__no-results {
    text-align:center;
    margin-top:60px;
  }

  &__item {
    display:flex;
    margin-bottom:20px;
  }
}

.sk-hits-list-hit {
  display:flex;

  &__poster {
    width:200px;
    flex:0 0 200px;
    padding:0px 20px;

    img {
      max-width:100%;
      margin:0 auto;
    }

  }

  &__details {
    flex:1 0 0;
    padding:10px;
  }

  &__title {
    line-height:20px;
    margin:0 0 5px;
    font-size:18px;
  }

  &__subtitle {
    line-height:20px;
    margin:0 0 5px;
    font-size:14px;
    color:#666;
    font-weight:300;
  }

  &__tags {
    line-height: 20px;
    margin:0;
    font-size: 14px;
    color: #666;
    font-weight: 300;
    list-style: none;
    padding:0;
    margin-bottom:10px;

    .sk-tag-filter {
      &:hover {
        color: $sk-action-text-color;
      }

      &:active {
        color: black;
      }
    }
  }

  &__text {
    line-height:22px;
    max-width:800px;
  }

}

.sk-action-bar {

  .sk-select, .sk-toggle {
    margin-left:15px;
  }
}

.sk-layout__filters {

  .sk-select select {
    width: 100%
  }
}

@import "../vars.scss";

.sk-select {
  position:relative;
  cursor:pointer;
  max-height:30px;
  display:inline-block;

  &.is-disabled {
    display:none
  }

  select {
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
    background:$sk-selector-background;
    padding:6px 10px;
    padding-right: 30px;
    border:1px solid $sk-selector-border-color;
    outline:0;
    font-weight:$sk-bold-weight;
    font-size:$sk-text-font-size;
    border-radius:$sk-border-radius;
    color:$sk-selector-text-color;
    height: initial;
    line-height: initial;
  }

  &:after {
    content:"";
    position:absolute;
    top:50%;
    margin-top:-3px;
    right:10px;
    @include sk-arrow-down(6px);
    pointer-events: none;
  }

}

@import "../vars.scss";

.sk-tabs {
  width: 100%;
  border-bottom: 1px solid $sk-border-color;
  list-style: none;
  padding: 0;
  margin: 4px 0;

  &.is-disabled {
    display:none;
  }

  &-option {
    cursor:pointer;
    display: inline-block;
    font-weight: $sk-medium-weight;
    font-size:$sk-list-font-size;
    color: $sk-action-text-color;
    padding: 10px 15px;
    text-decoration: none;
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-radius: $sk-border-radius $sk-border-radius 0 0;

    &.is-active {
      background-color: white;
      color:$sk-list-text-color;
      border-color:$sk-active-bg-color;
      cursor: default;
      border: 1px solid $sk-border-color;
      border-bottom-color: transparent;
    }

    &.is-disabled {
      cursor: initial;
      color: $sk-border-color;
    }

    &__text, &__count {
      display: inline;
    }

    &__count {
      &:before {
        content: " ("
      }

      &:after {
        content: ")"
      }
    }
  }
}

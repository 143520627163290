@import "../vars.scss";
@import "./search-icon.scss";

.sk-search-box {
  flex: auto;

  form {
    display:flex;
    margin:0;
    position:relative;
  }

  &__icon {
    flex:0 20px 20px;
    margin:10px 0 0 10px;
    
    &:before {
      content:"";
      @include search-icon;
      background-size: contain;
      height:20px;
      width:20px;
      display: block;
    }
    opacity:0.3;
  }

  input.sk-search-box__text {
    padding:10px 20px;
    width:100%;
    flex:3;
    font-size:18px;
    border:none;
    height: initial;
    line-height: initial;
    
    &:focus {
      outline:0;
    }
  }

  &__action {
    height:40px;
    visibility: hidden;
  }

  &__loader {
    flex:0 20px 20px;
    align-self: flex-end;
    margin:10px;

    &.is-hidden {
      display:none;
    }
  }
}

@import "filter-item.scss";
@import "../vars.scss";

.sk-hierarchical-refinement {

  &-list {

    &__header {
      margin-bottom:5px;
      font-weight:$sk-bold-weight;
      font-size: $sk-heading-font-size;
    }

    &__root {
      margin-left:-10px;
    }

    &__hierarchical-options {
      margin-left:10px;
    }
    
    &.is-disabled  {
      display:none;
    }
  }

  &-option {
    @include filter-option;
  }


}

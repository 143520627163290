@import "../vars.scss";

.sk-pagination-select {
}

.sk-pagination-navigation {

  .sk-toggle-option:first-child,
  .sk-toggle-option:last-child {
    flex: 4;
  }
}

@import "../vars.scss";

.sk-tag-cloud {
  text-align: justify;
  word-wrap: break-word;
  line-height: 16px;
  font-size: 10px;

  &.is-disabled {
    display:none;
  }

  &-option {
    display: inline;
    cursor:pointer;
    font-weight: $sk-light-weight;
    color: $sk-list-text-color;
    padding:4px 2px;
    border-right:none;
    text-decoration: none;

    &__text, &__count {
      display: inline;
    }

    &:before {
      content: ' ';
      display: inline;
    }

    &:after {
      content: ' ';
      display: inline;
    }

    &__count {
      &:before {
        content: ' (';
      }
      &:after {
        content: ')';
      }
    }

    &.is-active &__text {
      color: $sk-action-active-color;
      font-weight: $sk-medium-weight;
    }

    &.is-disabled {
      cursor: initial;
      color: $sk-disabled-text-color;
    }
  }
}

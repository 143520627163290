@import "../vars.scss";
@import "./search-icon.scss";

.sk-input-filter {
  flex:auto;
  border-radius: 3px;
  border: 1px solid $sk-border-color;

  form {
    display:flex;
    margin:0;
    position:relative;
  }

  &__icon {
    flex:0 20px 20px;
    margin-top:6px;
    margin-left:10px;
    &:before {
      content:"";
      @include search-icon;
      background-size: contain;
      height:20px;
      width:20px;
      display: block;
    }
    opacity:0.3;
  }

  &__text {
    padding:7px;
    width:100%;
    flex:3;
    font-size:14px;
    background: transparent;
    border:none;
    color: $sk-text-color;
    height: initial;
    line-height: initial;
    
    &:focus {
      outline:0;
    }
  }

  &__action {
    height:30px;
    visibility: hidden;
    display: none;
  }

  &__remove {
    &:before {
      flex:0 20px 20px;
      align-self: flex-end;
      padding:5px 10px;
      line-height: 30px;
      color: $sk-text-color;
      content: "x";
      cursor: pointer;
    }

    &.is-hidden:before {
      display:none;
    }
  }

}
